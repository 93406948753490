import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { useEffect, useState } from "react";

import {
  GoogleAuthProvider,
  getAuth,
  onAuthStateChanged,
  signOut,
  signInWithPopup,
} from "firebase/auth";

import Private from "./Private";
import Public from "./Public";

const firebaseConfig = {
  apiKey: "AIzaSyDvtyc2gMyFBDd2fsE4zHryLXLpyvrBnqI",
  authDomain: "el-shape.firebaseapp.com",
  databaseURL: "https://el-shape-default-rtdb.firebaseio.com/",
};
const firebaseApp = initializeApp(firebaseConfig);
const database = getDatabase(firebaseApp);

const App = () => {
  const [user, setUser] = useState(null);

  const auth = getAuth(firebaseApp);

  const onSignIn = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider);
  };

  const onSignOut = () => {
    signOut(auth);
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (authUser) => {
      if (authUser) {
        setUser(authUser);
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, [auth]);

  return (
    <div className="text-gray-900">
      {!user && <Public onSignIn={onSignIn} />}

      {user && (
        <Private database={database} user={user} onSignOut={onSignOut} />
      )}
    </div>
  );
};

export default App;
