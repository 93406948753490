import { onValue, ref } from "firebase/database";
import { useEffect, useState } from "react";

const Profile = ({ database, user, onSignOut }) => {
  const [profile, setProfile] = useState(null);

  useEffect(() => {
    const unsubscribe = onValue(
      ref(database, `profiles/${user.uid}`),
      (snapshot) => {
        const profile = snapshot.val() ?? {};
        profile.datapoints = profile.datapoints ?? {};
        setProfile(profile);
      }
    );
    return () => unsubscribe();
  }, [database, user]);

  if (!profile) return;

  return (
    <div className="p-4">
      <div className="mb-4">
        {Object.keys(profile.datapoints).map((date) => (
          <div key={date}>
            {date}: {profile.datapoints[date]}
          </div>
        ))}
      </div>

      <button
        className="border rounded py-1 px-3 font-semibold hover:bg-slate-100"
        onClick={onSignOut}
      >
        Log out
      </button>
    </div>
  );
};

export default Profile;
