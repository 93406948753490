import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ref, set } from "firebase/database";
import { useState } from "react";

const Navbar = ({
  database,
  groups,
  groupId,
  setGroupId,
  page,
  setPage,
  menuExpanded,
  setMenuExpanded,
}) => {
  return (
    <div className="sticky top-0">
      <div className="border-b flex bg-white">
        <div className="flex-1 flex border-r items-center truncate">
          <div className="flex-1 p-4 truncate">
            {page === "group" && (
              <GroupTitle
                database={database}
                groupId={groupId}
                name={groups[groupId].name}
              />
            )}
            {page === "profile" && <span className="font-bold">Profile</span>}
            {page === "new-group" && (
              <span className="font-bold">New group</span>
            )}
          </div>

          <div
            className="p-4 hover:bg-slate-100 cursor-pointer"
            onClick={() => setMenuExpanded(!menuExpanded)}
          >
            {!menuExpanded && <FontAwesomeIcon icon="chevron-down" />}
            {menuExpanded && <FontAwesomeIcon icon="chevron-up" />}
          </div>
        </div>

        <div
          className="py-4 px-5 hover:bg-slate-100 cursor-pointer"
          onClick={() => [
            setMenuExpanded(false),
            setGroupId(null),
            setPage("profile"),
          ]}
        >
          <FontAwesomeIcon icon="user" />
        </div>
      </div>

      {menuExpanded && (
        <div className="absolute w-full pr-[54px]">
          <div className="bg-white border-r shadow-sm">
            {Object.keys(groups).map((key) => (
              <div
                key={key}
                className="p-4 border-b hover:bg-slate-100 cursor-pointer font-bold truncate"
                onClick={() => [
                  setMenuExpanded(false),
                  setGroupId(key),
                  setPage("group"),
                ]}
              >
                {groups[key].name}
              </div>
            ))}

            <div
              className="p-4 border-b hover:bg-slate-100 cursor-pointer font-bold"
              onClick={() => [
                setMenuExpanded(false),
                setGroupId(null),
                setPage("new-group"),
              ]}
            >
              + New group
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Navbar;

const GroupTitle = ({ database, groupId, name }) => {
  const [newName, setNewName] = useState(name);
  const [showForm, setShowForm] = useState(false);

  const changeName = () => {
    set(ref(database, `groups/${groupId}/name`), newName);
    setShowForm(false);
  };

  return (
    <div className="">
      {!showForm && (
        <div className="flex gap-2 items-center">
          <div className="truncate font-bold">{name}</div>
          <FontAwesomeIcon
            icon="pen-to-square"
            className="text-gray-400"
            onClick={(e) => setShowForm(true)}
          />
        </div>
      )}

      {showForm && (
        <form onSubmit={changeName}>
          <input
            required
            autoFocus
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
            className="border-2 rounded -m-1 w-full px-0.5"
          />
        </form>
      )}
    </div>
  );
};
