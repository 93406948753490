import { onValue, ref, set } from "firebase/database";
import { useEffect, useState } from "react";
import Chart from "./Chart";
import moment from "moment";
import Datepicker from "react-tailwindcss-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";

const Group = ({ database, user, id, menuExpanded }) => {
  const [group, setGroup] = useState(null);

  useEffect(() => {
    const unsubscribe = onValue(ref(database, `groups/${id}`), (snapshot) =>
      setGroup(snapshot.val())
    );
    return () => unsubscribe();
  }, [database, id]);

  if (!group) return;

  return (
    <div className="flex flex-col">
      <div className="flex gap-2 p-4 border-b flex-wrap">
        {Object.keys(group.members).map((key) => (
          <div key={key}>
            {key === user.uid && (
              <Myself
                database={database}
                groupId={id}
                userId={user.uid}
                member={group.members[key]}
              />
            )}
            {key !== user.uid && (
              <div className="bg-slate-100 rounded-full py-0.5 px-3 text-sm font-semibold">
                {group.members[key].nickname}
              </div>
            )}
          </div>
        ))}
      </div>

      <Datapoint database={database} groupId={id} userId={user.uid} />

      {group.datapoints && (
        <div
          className={classNames("flex-1 p-4 pt-0 border-b", {
            "-z-10": menuExpanded,
          })}
        >
          <Chart group={group} />
        </div>
      )}

      <div className="bg-slate-100 rounded-lg p-4 flex flex-col gap-1 text-center text-gray-600 font-mono m-4">
        <div className="font-semibold">Secret</div>
        <div className="font-mono tracking-tight truncate">{group.secret}</div>
      </div>
    </div>
  );
};

export default Group;

const Myself = ({ database, groupId, userId, member }) => {
  const [changeNickname, setChangeNickname] = useState(false);
  const [nickname, setNickname] = useState(member.nickname);

  useEffect(() => {
    setChangeNickname(false);
    setNickname(member.nickname);
  }, [groupId, member.nickname]);

  const updateNickname = (event) => {
    event.preventDefault();
    set(
      ref(database, `groups/${groupId}/members/${userId}/nickname`),
      nickname
    );
    setChangeNickname(false);
  };

  return (
    <>
      {!changeNickname && (
        <div
          onClick={() => setChangeNickname(true)}
          className="bg-slate-100 rounded-full py-0.5 px-3 text-sm font-semibold text-gray-800 flex gap-2 items-center"
        >
          {member.nickname}{" "}
          <FontAwesomeIcon icon="pen-to-square" className="text-gray-500" />
        </div>
      )}
      {changeNickname && (
        <form onSubmit={updateNickname}>
          <input
            value={nickname}
            onChange={(e) => setNickname(e.target.value)}
            autoFocus
            className="border rounded-full px-3 text-sm"
          />
        </form>
      )}
    </>
  );
};

const Datapoint = ({ database, groupId, userId }) => {
  const [weight, setWeight] = useState(0);
  const [date, setDate] = useState({
    startDate: moment().format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  });

  const addDatapoint = (event) => {
    event.preventDefault();
    set(
      ref(database, `groups/${groupId}/datapoints/${date.startDate}/${userId}`),
      weight * 1000
    );
    set(
      ref(database, `profiles/${userId}/datapoints/${date.startDate}`),
      weight * 1000
    );
  };

  return (
    <form className="flex gap-2 p-4" onSubmit={addDatapoint}>
      <Datepicker
        containerClassName="border-0"
        inputClassName="border rounded py-1 px-3 w-32"
        toggleClassName="hidden"
        asSingle={true}
        useRange={false}
        displayFormat={"DD/MM/YYYY"}
        value={date}
        onChange={setDate}
      />

      <input
        className="border rounded py-1 px-3 w-20"
        type="number"
        step="0.01"
        value={weight}
        onChange={(e) => setWeight(e.target.value)}
      />

      <button className="border rounded py-1 px-3 hover:bg-slate-100 font-semibold">
        OK
      </button>
    </form>
  );
};
