import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faUser,
  faPenToSquare,
  faChevronUp,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
library.add(faUser, faPenToSquare, faChevronUp, faChevronDown);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
