import _ from "lodash";
import moment from "moment";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const Chart = ({ group }) => {
  const begin = _.min(Object.keys(group.datapoints));
  const end = _.max(Object.keys(group.datapoints));
  const range = _.range(0, moment(end).diff(begin, "days") + 1);
  const dateRange = _.map(range, (i) =>
    moment(begin).add(i, "days").format("YYYY-MM-DD")
  );

  const lines = _.map(group.members, (member, uid) => ({
    uid,
    name: member.nickname,
    data: [],
  }));

  _.each(lines, (line) => {
    _.each(dateRange, (date) => {
      const value = _.get(group, `datapoints.${date}.${line.uid}`);
      line.data.push({
        date,
        value: value ? value / 1000 : null,
      });
    });
    delete line.uid;
  });

  const values = _.chain(group.datapoints)
    .values()
    .map(_.values)
    .flatten()
    .sort()
    .map((v) => v / 1000)
    .valueOf();
  const domain = [_.floor(_.min(values)) - 2, _.ceil(_.max(values)) + 2];

  return (
    <ResponsiveContainer width="100%" height={400}>
      <LineChart margin={{ left: -25 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" type="category" allowDuplicatedCategory={false} />
        <YAxis dataKey="value" domain={domain} />
        <Tooltip />
        {lines.map((line, i) => (
          <Line
            connectNulls
            key={i}
            dataKey="value"
            data={line.data}
            name={line.name}
            stroke="#8884d8"
            type="monotone"
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default Chart;
