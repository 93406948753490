import {
  equalTo,
  get,
  orderByChild,
  push,
  query,
  ref,
  set,
} from "firebase/database";
import md5 from "md5";
import { useState } from "react";

const NewGroup = ({ database, user, setGroupId, setPage }) => {
  const [secret, setSecret] = useState("");
  const [name, setName] = useState("");

  const createGroup = (event) => {
    event.preventDefault();
    const group = {
      name,
      secret: md5(Math.random()),
      members: { [user.uid]: { member: true, nickname: user.displayName } },
      data: [],
    };
    push(ref(database, `groups`), group).then((snapshot) => [
      setGroupId(snapshot.key),
      setPage("group"),
      setSecret(""),
      setName(""),
    ]);
  };

  const joinGroup = (event) => {
    event.preventDefault();
    get(
      query(ref(database, "groups"), orderByChild(`secret`), equalTo(secret))
    ).then((snapshot) => {
      const result = snapshot.val();
      if (!result) return;

      const groupId = Object.keys(result)[0];
      set(ref(database, `groups/${groupId}/members/${user.uid}`), {
        member: true,
        nickname: user.displayName,
      });
      setGroupId(groupId);
      setPage("group");
      setSecret("");
      setName("");
    });
  };

  return (
    <div>
      <div className="px-4 py-8 border-b">
        <form className="flex flex-col gap-4" onSubmit={joinGroup}>
          <input
            type="text"
            className="border rounded py-2 px-3"
            placeholder="Secret"
            value={secret}
            onChange={(e) => setSecret(e.target.value)}
            required
          />

          <button className="border rounded py-2 px-3 font-semibold hover:bg-slate-100">
            Join group
          </button>
        </form>
      </div>

      <div className="px-4 py-8 flex flex-col">
        <form className="flex flex-col gap-4" onSubmit={createGroup}>
          <input
            type="text"
            className="border rounded py-2 px-3"
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />

          <button className="border rounded py-2 px-3 font-semibold hover:bg-slate-100">
            Create new group
          </button>
        </form>
      </div>
    </div>
  );
};

export default NewGroup;
