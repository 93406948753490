const Public = ({ onSignIn }) => {
  return (
    <div className="h-screen flex items-center">
      <div className="w-full text-center">
        <button
          className="border rounded py-1 px-3 font-bold hover:bg-slate-100"
          onClick={onSignIn}
        >
          Log in
        </button>
      </div>
    </div>
  );
};

export default Public;
