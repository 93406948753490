import { useEffect, useState } from "react";
import Navbar from "./Navbar";
import Group from "./Group";
import Profile from "./Profile";
import NewGroup from "./NewGroup";
import { equalTo, onValue, orderByChild, query, ref } from "firebase/database";

const Private = ({ database, user, onSignOut }) => {
  const [page, setPage] = useState("profile");
  const [groups, setGroups] = useState({});
  const [groupId, setGroupId] = useState(null);
  const [menuExpanded, setMenuExpanded] = useState(false);

  useEffect(() => {
    onValue(
      query(
        ref(database, "groups"),
        orderByChild(`members/${user.uid}/member`),
        equalTo(true)
      ),
      (snapshot) => setGroups(snapshot.val() ?? {})
    );
  }, [database, user]);

  return (
    <div>
      <Navbar
        database={database}
        groups={groups}
        groupId={groupId}
        setGroupId={setGroupId}
        page={page}
        setPage={setPage}
        menuExpanded={menuExpanded}
        setMenuExpanded={setMenuExpanded}
      />

      {page === "profile" && (
        <Profile database={database} user={user} onSignOut={onSignOut} />
      )}

      {page === "new-group" && (
        <NewGroup
          database={database}
          user={user}
          setGroupId={setGroupId}
          setPage={setPage}
        />
      )}

      {page === "group" && (
        <Group
          database={database}
          user={user}
          id={groupId}
          menuExpanded={menuExpanded}
        />
      )}
    </div>
  );
};

export default Private;
